import React from "react";
import Accordion from "../../../../../components/accordian";
import Form from "../../../../../components/forms";
import Pricing from "./pricing";
import Info from "./info";
import Cover from "./cover";
import Batches from "./batches";
import Whitelist from "./whitelist";
import CoHosts from "./coHosts";

const CourseContent = ({
	request,
	courseEditRequest,
	toggleCertification,
	toggleYoutubeUrl,
}) => {
	console.log({ request });
	return (
		<>
			<Cover />
			<Info />
			<Accordion title="Course Description">
				<Form
					formType="text"
					name={`${request.id}-description`}
					defaultVal={request.profile.description}
					submitRequest={async (val) =>
						await courseEditRequest({ courseDescription: val })
					}
					editToggle={true}
					type="texteditor"
				/>
			</Accordion>
			<Whitelist />
			<Accordion title="Course Certification">
				<Form
					formType="toggle"
					name={`${request.id}-hasCertification`}
					defaultVal={request.hasCertification}
					onChange={async (val) => await toggleCertification(val)}
					label={"has Certification"}
					type="toggle"
				/>
				<Form
					formType="text"
					name={`${request.id}-Certification_Price_Percentange`}
					defaultVal={request.certificationFeePremium}
					submitRequest={async (val) =>
						await courseEditRequest({ certificationFeePremium: val })
					}
					label={"Certification Price Percentange"}
					type="text"
				/>
			</Accordion>
			<Accordion title="Co Hosts">
				<CoHosts/>
			</Accordion>
			<Accordion title="Course Youtube Intergration">
				<Form
					formType="toggle"
					name={`${request.id}-youtubeUrl-toggle`}
					defaultVal={request.hasYoutubeUrl}
					onChange={async (val) => await toggleYoutubeUrl(val)}
					label={"has Youtube Url"}
					type="toggle"
				/>
				<Form
					formType="text"
					name={`${request.id}-Course_Youtube_Url`}
					defaultVal={request.youtubeUrl}
					submitRequest={async (val) =>
						await courseEditRequest({ youtubeUrl: val })
					}
					label={"Youtube Url"}
					type="text"
				/>
			</Accordion>
			<Accordion title="Course Pre-requisites">
				<Form
					formType="text"
					name={`${request.id}-pre-req`}
					defaultVal={request.profile.preRequisites}
					submitRequest={async (val) =>
						await courseEditRequest({ preRequisites: val })
					}
					type="texteditor"
					editToggle={true}
				/>
			</Accordion>
			<Accordion title="Course Objectives">
				<Form
					formType="text"
					name={`${request.id}-objectives`}
					defaultVal={request.profile.courseObjectives}
					submitRequest={async (val) =>
						await courseEditRequest({ courseObjectives: val })
					}
					type="texteditor"
					editToggle={true}
				/>
			</Accordion>
			<Accordion
				title="Batches"
				disabled={request.type !== "live" && !request.profile.isGroupCourse}
				TransitionProps={{ unmountOnExit: true }}
			>
				<Batches />
			</Accordion>
			<Accordion title="Pricing">
				<Pricing />
			</Accordion>
			<Accordion title="Meta">
				<Form
					formType="text"
					name={`${request.id}-title`}
					defaultVal={request.metaTitle}
					submitRequest={async (val) =>
						await courseEditRequest({ metaTitle: val })
					}
					type="text"
					editToggle={true}
					label="title"
				/>
				<Form
					formType="text"
					name={`${request.id}-description`}
					defaultVal={request.metaDescription}
					submitRequest={async (val) =>
						await courseEditRequest({ metaDescription: val })
					}
					type="text"
					editToggle={true}
					label="description"
				/>
				<Form
					formType="text"
					name={`${request.id}-schema`}
					defaultVal={request.metaSchema}
					submitRequest={async (val) =>
						await courseEditRequest({ metaSchema: val })
					}
					type="text"
					editToggle={true}
					label="schema"
				/>
				<Form
					formType="text"
					name={`${request.id}-keywords`}
					defaultVal={request.metaKeywords}
					submitRequest={async (val) =>
						await courseEditRequest({ metaKeywords: val })
					}
					type="text"
					editToggle={true}
					label="keywords"
				/>
			</Accordion>
		</>
	);
};

export default CourseContent;
