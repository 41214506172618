import React, { useState } from "react";
import { withStore } from "../../../../../../utils/withStore";
import SubscriptionStore from "../../stores/subscriptionStore";
import SubscriptionTable from "./components/subscriptionTable";
import Search from "../../../../../../components/search";
import { createFilter } from "react-search-input/lib/util";
import { SubscriptionsEl } from "../../elements/subscriptions";
import Loading from "../../../../../../components/loading";
import { getYearAndMonthOptions } from "../../helpers";
import Button from "../../../../../../components/button";
import date from "date-and-time";

const KEYS_TO_FILTER = [
	"name",
	"email",
	"plan",
	"purchaseDate",
	"endDate",
	"frequency",
];

function Subscriptions() {
	const {
		subscriptions = [],
		loading,
		loadFailed,
		ActiveSubscriptions,
		ActiveSubscriptionsLoading,
		ActiveSubscriptionsLoadFailed,
	} = SubscriptionStore.useContainer();
	const [query, setQuery] = useState("");
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
	const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
	const { years, months } = getYearAndMonthOptions();

	subscriptions.sort((a, b) => b.bills.length - a.bills.length);

	let filteredSubscriptions = subscriptions
		.filter(createFilter(query, KEYS_TO_FILTER))
		.filter(({ purchaseDate }) => {
			const Date = date.parse(purchaseDate, "DD MMM YYYY hh:mmA");
			return (
				(selectedYear === -1 || Date.getFullYear() === selectedYear) &&
				(selectedMonth === -1 || Date.getMonth() === selectedMonth)
			);
		});
	const jsonToCsv = (json) => {
		delete json["amount"];
		delete json["pricing"];
		const headers = Object.keys(json[0]);
		const csvRows = [headers.join(",")];

		json.forEach((row) => {
			const values = headers.map((header) => `"${row[header] || ""}"`);
			csvRows.push(values.join(","));
		});

		return csvRows.join("\n");
	};
	// Trigger the CSV download
	const downloadCSV = () => {
		if (!filteredSubscriptions || filteredSubscriptions.length === 0) {
			alert("No data to download!");
			return;
		}
		delete filteredSubscriptions["bills"];
		delete filteredSubscriptions["total_bills"];
		const csvData = jsonToCsv(filteredSubscriptions);
		const blob = new Blob([csvData], { type: "text/csv" });
		const url = URL.createObjectURL(blob);

		const a = document.createElement("a");
		a.href = url;
		a.download = `recordings.csv`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};
	const downloadActiveSubsCSV = () => {
		if(ActiveSubscriptionsLoadFailed){
			alert("Failed to load active subscriptions");
			return;
		}
		if(ActiveSubscriptionsLoading){
			alert("Loading data, please wait");
			return;
		}
		if (!ActiveSubscriptions || ActiveSubscriptions.length === 0) {
			alert("No data to download!");
			return;
		}
		delete ActiveSubscriptions["bills"];
		delete ActiveSubscriptions["total_bills"];
		const csvData = jsonToCsv(ActiveSubscriptions);
		const blob = new Blob([csvData], { type: "text/csv" });
		const url = URL.createObjectURL(blob);

		const a = document.createElement("a");
		a.href = url;
		a.download = `recordings.csv`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	return (
		<Loading loading={loading} loadFailed={loadFailed}>
			<SubscriptionsEl>
				<div className="filter-container">
					<Search placeholder={"Search"} onChange={setQuery} />
					<div className="month-filter">
						<select
							value={selectedYear}
							onChange={(e) => setSelectedYear(Number(e.target.value))}
						>
							{years.map((year) => (
								<option key={year} value={year}>
									{year}
								</option>
							))}
							<option value={-1}>All </option>
						</select>
						<select
							value={selectedMonth}
							onChange={(e) => setSelectedMonth(Number(e.target.value))}
						>
							{months.map((month, index) => (
								<option key={index} value={index}>
									{month}
								</option>
							))}
							<option value={-1}>All </option>
						</select>
						<Button onClick={downloadCSV}>Download Csv</Button>
						<Button onClick={downloadActiveSubsCSV}>Download Acitve Subscriptions Csv</Button>
					</div>
				</div>
				<SubscriptionTable subscriptions={filteredSubscriptions} />
			</SubscriptionsEl>
		</Loading>
	);
}

export default withStore([SubscriptionStore])(Subscriptions);
