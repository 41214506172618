import { Redirect, Router } from "@reach/router";
import React from "react";
import TabMap from "../../../../components/tabMap";
import PrivateRoute from "../../../../utils/privateRoute";
import { courseFilterTabs } from "../../config/routes";
import { ActiveSecEl, FilterNavEl } from "../../elements";
import FilterTab from "../common/filterTab";
import RequestView from "../requestView";
import CourseContent from "./components/courseContent";
import Statusbar from "./components/statusbar";
import CourseStore from "./store/courseStore";
import TeacherStore from "../teachers/store/teacherStore";

const Courses = () => {
  return (
    <ActiveSecEl>
      <FilterNavEl>
        <TabMap
          tabs={courseFilterTabs}
          tabStyle={(props) => <FilterTab {...props} />}
          replace
        />
      </FilterNavEl>
      <CourseStore.Provider>
      <TeacherStore.Provider>
        <Router component={ActiveSecEl}>
          <Redirect from="/" to="live" noThrow />
          <RequestView
            path=":filter/*"
            infoComponent={null}
            contentComponent={CourseContent}
            statusComponent={Statusbar}
            Store={CourseStore}
          />
        </Router>
      </TeacherStore.Provider>
      </CourseStore.Provider>
    </ActiveSecEl>
  );
};

export default PrivateRoute(Courses);
