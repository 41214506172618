import { map, pipe } from "ramda";
import useSWR from "swr";
import { createContainer } from "unstated-next";
import Subscription from "../../../../../schemas/subscription";

const key = ["admin/subscriptions", "protected", pipe(map(Subscription))];
const ActiveSubscriptionsKey = ["admin/subscriptions/active", "protected", pipe(map(Subscription))];

const SubscriptionStore = createContainer(() => {
  const { data: subscriptions, mutate, error } = useSWR(key);
  const { data: ActiveSubscriptions, mutate:ActiveSubscriptionsMutate, ActiveSubscriptionsError } = useSWR(ActiveSubscriptionsKey);
  
  return {
    loading: !subscriptions,
    loadFailed: Boolean(error),
    subscriptions,
    mutate,
    ActiveSubscriptionsLoading: !ActiveSubscriptions,
    ActiveSubscriptionsLoadFailed: Boolean(ActiveSubscriptionsError),
    ActiveSubscriptions,
    ActiveSubscriptionsMutate,
    // sidebarTabs: createSubscriptionTabs(subscriptions)
  };
});

export default SubscriptionStore;
