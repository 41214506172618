import {
	applySpec,
	converge,
	map,
	nth,
	pathOr,
	pipe,
	sortBy,
	subtract,
} from "ramda";
import { parseDoc } from "../helpers/dataParsers";

const status = (course) => {
	const status = pathOr("", ["status"])(course);
	return course?.tainted && status === "LIVE" ? "PENDING_EDITS" : status;
};

const coverImages = (course) => {
	const imagesEdited = pathOr({}, [
		"teacher",
		"teacherInfo",
		"coverImagesEdited",
	])(course);
	const imagesRaw = pathOr({}, ["teacher", "teacherInfo", "coverImagesRaw"])(
		course
	);
	const cur = pathOr("", ["info"])(course);
	const images = { ...imagesEdited, ...imagesRaw, ...cur };

	var flg = {};
	return Object.entries(images)
		.map(([key, val]) => {
			return { id: key, ...parseDoc(val) };
		})
		.filter(({ url }) => url !== "")
		.filter(({ url, id }) => {
			if (flg[url] || flg[id]) return false;
			flg[url] = flg[id] = true;
			return true;
		});
};

const courseUrl = (course) => {
	const grpUrl = pathOr("", ["info", "courseUrl"])(course);
	const livdemyId = pathOr("", ["teacher", "teacherInfo", "livdemyId"])(course);
	return `${livdemyId}/course/${grpUrl}`;
};

const batches = pipe(
	pathOr([], ["batches"]),
	map(
		applySpec({
			id: pathOr("", ["id"]),
			registrationCount: pathOr("", ["registrationCount"]),
			name: pathOr("", ["title"]),
			batchLimit: pathOr("", ["batchLimit"]),
			slots: pipe(
				pathOr([], ["dateSlots"]),
				map(
					applySpec({
						startTime: pathOr(0, ["startTime"]),
						endTime: pathOr(0, ["endTime"]),
					})
				),
				sortBy(pathOr(0, ["startTime"]))
			),
			duration: pipe(
				pathOr([], ["dateSlots"]),
				nth(0),
				converge(subtract, [pathOr(0, ["endTime"]), pathOr(0, ["startTime"])])
			),
			classes: pathOr({}, ["classes"]),
		})
	)
);

const Course = applySpec({
	id: pathOr("", ["id"]),
	teacherId: pathOr("", ["teacher", "teacherInfo", "id"]),
	status,
	courseUrl,
	hasCertification: pathOr(false, ["hasCertification"]),
	hasYoutubeUrl: pathOr(false, ["hasYoutubeUrl"]),
	youtubeUrl: pathOr("", ["youtubeUrl"]),
	certificationFeePremium: pathOr(50, ["certificationFeePremium"]),
	profile: applySpec({
		name: pathOr("", ["info", "title"]),
		subtext: pathOr("", ["teacher", "teacherProfile", "name"]),
		isGroupCourse: pathOr(false, ["isGroupCourse"]),
		description: pathOr("", ["info", "description"]),
		preRequisites: pathOr("", ["info", "preRequisites"]),
		courseObjectives: pathOr("", ["info", "courseObjectives"]),
	}),
	fee: applySpec({
		amount: pathOr(0, ["fee", "amount"]),
		currency: pathOr("", ["fee", "currency"]),
	}),
	cohosts: pipe(pathOr([], ["co-hosts"]), (coHostIds) => Object.keys(coHostIds)),
	courseCover: pathOr("", ["info", "courseCover", "location"]),
	courseCoverVideo: pathOr("", ["info", "courseCoverVideo", "location"]),
	coverVideoUrl: pathOr("", ["coverVideoUrl"]),
	type: pathOr("live", ["type"]),
	updatedAt: pathOr(new Date().getTime(), ["updatedAt"]),
	metaDescription: pathOr("", ["metaDescription"]),
	metaTitle: pathOr("", ["metaTitle"]),
	metaSchema: pathOr("", ["metaSchema"]),
	metaKeywords: pathOr("", ["metaKeywords"]),
	countryDiscounts: pathOr({}, ["coupon", "value"]),
	coverImages,
	coverVideoUrl: pathOr("", ["coverVideoUrl"]),
	priceMatrix: pathOr({}, ["priceMatrix"]),
	isAuditionVideoReq: pathOr(true, ["isAuditionVideoReq"]),
	isApprovalReq: pathOr(true, ["isApprovalReq"]),
	nClasses: pathOr(0, ["info", "noOfClasses"]),
	batches,
});

export default Course;
