import styled from "styled-components";
import { hFlex, modalBox, vFlex } from "../../../../../styles/style-helpers";
import { H3, H5 } from "../../../../../styles/typography";

export const PricingEl = styled.div`
  ${vFlex};
  width: 100%;

  > div:first-child {
    padding-bottom: 1em;
  }

  .grp {
    > * {
      margin: 0.5em 0;
    }

    ${H3} {
      margin-bottom: 0.5em;
    }

    ${H5} {
      margin: 1.5em 0;
    }

    width: 100%;
    margin-bottom: 0.5em;
  }
`;
export const CoHostEl = styled.div`
  ol {
    padding: 0.1em 0 0 1em;
  }

  button {
    margin-left: 1em;
  }

  li:hover {
    button {
      opacity: 1;
    }
  }

  button {
    opacity: 0;
  }
`;
export const PaymentInfoEl = styled.div`
  ${modalBox}
  ${vFlex}
  width: 95%;
  max-height: 85vh;
  .filter-container{
    display:flex;
    justify-content: space-between;
    width:100%;

    .month-filter{
      display:flex;
      margin:10px 0px;
      gap:10px;
    }
  }
  .basic-info {
    ${hFlex};
    width: 100%;
    justify-content: space-between;

    .selector {
      min-width: 8em;

      .MuiInputBase-root {
        width: 100%;
      }
    }
  }
`;
